html {
    -webkit-font-smoothing: antialiased;
}

html,
body {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: 'Open Sans', sans-serif;
}

a {
    transition: all 0.5s ease;
    -webkit-transition-delay: all 0.5s ease;
    -moz-transition-delay: all 0.5s ease;
    -ms-transition-delay: all 0.5s ease;
    -o-transition-delay: all 0.5s ease;
    color: #fff;
    text-decoration: none;
}

a:hover {
    transition: all 0.5s ease;
    -webkit-transition-delay: all 0.5s ease;
    -moz-transition-delay: all 0.5s ease;
    -ms-transition-delay: all 0.5s ease;
    -o-transition-delay: all 0.5s ease;
    color: #b4ad9c;
    text-decoration: none;
}

body {
    padding-top: 0px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 400;
    line-height: 1.1;
    color: #fff;
}

.badge-pos {
    position: absolute;
    right: 0px;
    top: 125px;
    z-index: 11113;
    display: block;
    width: 224px;
    height: 224px;
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 24px;
}

p {
    color: #fff;
}

.home-container p {
    padding-left: 15px;
    padding-right: 15px;
    color: #b4ad9c;
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 20px;
}

p.home-intro {
    padding-left: 15px;
    padding-right: 15px;
    color: #b4ad9c;
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 20px;
}

div.category-container p {
    padding-left: 15px;
    padding-right: 15px;
    color: #b4ad9c;
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 20px;
}

p.category-intro {
    padding-left: 15px;
    padding-right: 15px;
    color: #b4ad9c;
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 20px;
}

.home-container h1 {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
}

.home-h1 {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
}

.navbar-static-top {
    z-index: 1000;
    border-width: 0px;
}

.navbar-nav {
    margin-top: 20px;
}

.navbar {
    margin-bottom: 0px;
}

.navbar-inverse {
    background-color: transparent;
    border-color: #080808;
    background-image: url(../images/black-overlay.png);
    background-repeat: repeat;
}

.navbar-brand {
    float: left;
    height: 85px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.navbar-inverse .navbar-nav>li {
    padding-left: 10px;
    padding-right: 10px;
}

.navbar-inverse .navbar-nav>li>a {
    font-size: 11px;
    padding-top: 15px;
    padding-bottom: 24px;
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 700;
    color: #b3ac9c;
    border-bottom: solid 6px transparent;
}

.navbar-inverse .navbar-nav>li>a.active,
.navbar-inverse .navbar-nav>li>a:hover {
    color: #fff;
    border-bottom: solid 6px #ee5624;
}

.navbar-inverse .navbar-nav>li>a:focus {
    outline: none;
    color: #b3ac9c;
}

.tel {
    float: left;
    display: block;
    padding-top: 28px;
    font-size: 19px;
    color: #d1c3a8;
    font-weight: 400;
    line-height: 22px;
}

.tel strong {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
}

.slogan {
    float: left;
    display: block;
    clear: left;
    color: #fff;
    font-size: 13px;
    letter-spacing: 0.3px;
    font-weight: 400;
}

.b-cover {
    background-image: url(../images/black-overlay.png);
    background-repeat: repeat;
    margin-bottom: 20px;
}
/* Boxes */

.thumbnail {
    position: relative;
    padding: 0px;
    background-color: #000;
    border: none;
}

.home-item {
    height: auto;
}

.thumb-image-holder {
    position: relative;
    height: auto;
    overflow: hidden;
}

a.thumb-link {
    position: absolute;
    display: block;
    z-index: 1211;
    height: 100%;
    width: 100%;
}

.thumbnail h3 {
    transition: all 0.5s ease;
    -webkit-transition-delay: all 0.5s ease;
    -moz-transition-delay: all 0.5s ease;
    -ms-transition-delay: all 0.5s ease;
    -o-transition-delay: all 0.5s ease;
    margin-top: 0px;
    padding-top: 0px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}

.thumbnail:hover h3 {
    transition: all 0.5s ease;
    -webkit-transition-delay: all 0.5s ease;
    -moz-transition-delay: all 0.5s ease;
    -ms-transition-delay: all 0.5s ease;
    -o-transition-delay: all 0.5s ease;
    color: #ee5624;
}

.thumbnail .caption {
    transition: all 0.5s ease;
    -webkit-transition-delay: all 0.5s ease;
    -moz-transition-delay: all 0.5s ease;
    -ms-transition-delay: all 0.5s ease;
    -o-transition-delay: all 0.5s ease;
    position: relative;
    padding: 15px 20px 0px 20px;
    color: #333;
    height: 120px;
    border-top: solid 3px #000;
    background-color: #000;
    width: 100%;
}

.thumbnail:hover .caption,
.thumbnail:hover .caption.cap-single {
    transition: all 0.5s ease;
    -webkit-transition-delay: all 0.5s ease;
    -moz-transition-delay: all 0.5s ease;
    -ms-transition-delay: all 0.5s ease;
    -o-transition-delay: all 0.5s ease;
    border-top: solid 3px #ee5624;
}

.thumbnail .caption.cap-single {
    transition: all 0.5s ease;
    -webkit-transition-delay: all 0.5s ease;
    -moz-transition-delay: all 0.5s ease;
    -ms-transition-delay: all 0.5s ease;
    -o-transition-delay: all 0.5s ease;
    position: relative;
    padding: 15px 20px 0px 20px;
    color: #333;
    height: 55px;
    border-top: solid 3px #000;
    background-color: #000;
    width: 100%;
}

.home-item .caption a {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    padding-bottom: 5px;
    display: block;
}

.home-item .caption a:hover {
    color: #ee5624;
    text-decoration: none;
}

.home-item .caption p {
    font-size: 14px;
    font-weight: 500;
    color: #b7b09f;
    padding-bottom: 0px;
}

.link-icon {
    transition: all 0.5s ease;
    -webkit-transition-delay: all 0.5s ease;
    -moz-transition-delay: all 0.5s ease;
    -ms-transition-delay: all 0.5s ease;
    -o-transition-delay: all 0.5s ease;
    margin-top: 100%;
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(../images/link-icon.png);
    background-position: center center;
    z-index: 111;
}

.thumbnail:hover .link-icon {
    transition: all 0.5s ease;
    -webkit-transition-delay: all 0.5s ease;
    -moz-transition-delay: all 0.5s ease;
    -ms-transition-delay: all 0.5s ease;
    -o-transition-delay: all 0.5s ease;
    margin-top: 0px;
}

.social {
    padding-left: 10px;
}

.social img {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.social p {
    float: right;
    padding-top: 15px;
    padding-right: 20px;
    font-size: 11px;
    font-weight: 700;
}

.under-nav-top-margin {
    margin-top: 40px;
}

.top-pad {
    padding-top: 20px;
}

.breadcrumbs-holder {
    height: 70px;
}

.breadcrumb {
    padding-top: 30px;
    padding-bottom: 0px;
    padding-right: 30px;
    margin-bottom: 0px;
    list-style: none;
    background-color: transparent;
    border-radius: 4px;
    float: right;
}

.breadcrumb li {
    color: #b4ad9c!important;
    font-weight: 400;
}

.breadcrumb li a {
    font-weight: 700;
    color: #fff;
}

.breadcrumb li a:hover {
    color: #b4ad9c!important;
    text-decoration: none;
}

.inner-title {
    margin-top: 20px;
    font-weight: 700;
    padding-left: 25px;
    float: left;
}

.cream-title {
    font-weight: 400;
    color: #b7b09f;
}
/* -------------------- News Page --------------------*/

.right-gutter {
    background-color: #1c1c1c;
    -webkit-box-shadow: inset 3px 0 7px rgba(0, 0, 0, .5);
    -moz-box-shadow: inset 3px 0 7px rgba(0, 0, 0, .5);
    box-shadow: inset 3px 0 7px rgba(0, 0, 0, .5);
    padding: 20px 25px 20px 25px;
}

.news-item h2, .product-item h2 {
    padding-bottom: 15px;
    background-image: url(../images/border.jpg);
    background-position: bottom;
    background-repeat: repeat-x;
    margin-bottom: 20px;
    margin-top: 25px;  
    line-height: 32px;
}

.news-item, .product-item {
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: 10px;
}
.news-item-detail {
    padding-bottom: 100px;
}
.news-image-holder {
    position: relative;
    margin-bottom: 20px;
}

.news-image-detail {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-image: url(../images/black-overlay.png);
    height: 40px;
    padding: 10px;
    font-size: 14px;
    font-style: italic;
    color: #aca595;
}

.news-image-detail a {
    color: #ee5624;
}

.news-image-detail a:hover {
    color: #fff;
}

.news-item p.intro, .product-item p.intro {
    font-size: 16px;
    color: #fff;
    padding-bottom: 10px;
}
.news-item p, .product-item p {
    font-size: 14px;
    color: #fff;
    padding-bottom: 15px;
}
.news-item ul, .product-item ul {
    padding-left: 0px;
    margin-left: 5px;
    padding-bottom: 10px;
}
.news-item li, .product-item li {
    font-size: 14px;
    color: #fff;
    padding-bottom: 0px;
    list-style: none;
    padding-left: 15px;
    line-height: 20px;
    background-image: url(../images/bullet.png);
    background-repeat: no-repeat;
    background-position: left center;
}

.news-item p.intro a, .product-item p.intro a {
    color: #ee5624;
}

.news-item p.intro a:hover, .product-item p.intro a:hover {
    color: #fff;
}

#custom-search-input {
    padding: 3px;
    border: solid 1px #aca796;
    border-radius: 0px;
    background-color: #aca796;
}

#custom-search-input input {
    border: 0;
    box-shadow: none;
    color: #3c3c3c;
}

#custom-search-input button {
    margin: 2px 0 0 0;
    background: none;
    box-shadow: none;
    border: 0;
    color: #575757;
    padding: 0 8px 0 10px;
    border-left: solid 1px #575757;
    background-color: #aca796;
}

#custom-search-input button:hover {
    border: 0;
    box-shadow: none;
    border-left: solid 1px #575757;
}

#custom-search-input .glyphicon-search {
    font-size: 23px;
}

.input-lg {
    height: 40px;
    padding: 0px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 0px;
    background-color: #aca796;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.82857143;
    color: #555;
    background-color: #aca796;
    background-image: none;
    border: 1px solid #aca796;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

}
 
.form-control  {

    height: 44px;
        margin-bottom: 15px;

}

.form-control::-webkit-input-placeholder {
    color: #3c3c3c;
}

.form-control:-moz-placeholder {
    color: #3c3c3c;
}

.form-control::-moz-placeholder {
    color: #3c3c3c;
}

.form-control:-ms-input-placeholder {
    color: #3c3c3c;
}

.right-gutter h6 {
    font-size: 16px;
    font-weight: 600;
    padding-top: 20px;
    text-transform: uppercase;
}

.no-pads {
    padding-left: 0px;
    padding-right: 0px;
}

.recent-item {
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #353535;
}
.recent-img {
      padding-left: 0px;
    padding-right: 15px;  
}
.recent-img img {
    border: 1px solid #000;
    transition:all 0.5s ease;
    -webkit-transition-delay:all 0.5s ease;
    -moz-transition-delay:all 0.5s ease;
    -ms-transition-delay:all 0.5s ease;
    -o-transition-delay:all 0.5s ease;
}

.recent-img img:hover {
    transition:all 0.5s ease;
    -webkit-transition-delay:all 0.5s ease;
    -moz-transition-delay:all 0.5s ease;
    -ms-transition-delay:all 0.5s ease;
    -o-transition-delay:all 0.5s ease;
    border: 1px solid #ed5523;
}

.recent-img.pdf img {
    border: none;
    transition:all 0.5s ease;
    -webkit-transition-delay:all 0.5s ease;
    -moz-transition-delay:all 0.5s ease;
    -ms-transition-delay:all 0.5s ease;
    -o-transition-delay:all 0.5s ease;
}


.recent-text a {
    color: #ed5523;
    font-size: 15px;
}

.recent-text a:hover {
    color: #fff;
    text-decoration: none;
}

.recent-text p {
    font-size: 13px;
    color: #a9a293;
}
.archive ul {
  margin-left: 0px;
  padding-left: 0px;
}
.archive ul li {
  list-style: none;
}
.archive ul li a {
    color: #ed5523;
    font-size: 14px;
    padding-left: 0px;
    line-height: 30px;
    transition:all 0.5s ease;
    -webkit-transition-delay:all 0.5s ease;
    -moz-transition-delay:all 0.5s ease;
    -ms-transition-delay:all 0.5s ease;
    -o-transition-delay:all 0.5s ease;
}
.archive ul li a:hover {
    color: #fff;
    padding-left: 10px;
    transition:all 0.5s ease;
    -webkit-transition-delay:all 0.5s ease;
    -moz-transition-delay:all 0.5s ease;
    -ms-transition-delay:all 0.5s ease;
    -o-transition-delay:all 0.5s ease;
}
.fa-angle-double-right {
  font-size: 10px;
  color:#9f9f9f;
  margin-top: -2px;
  margin-left: 5px;
  margin-right: 10px;


}
.pagination-holder {

    margin-left: -15px;
    margin-right: -15px;
    background-image: url(../images/black-overlay.png);
    background-repeat: repeat;

    padding-left: 30px;

    display: block;



}
.pagination {

  margin-top: 15px;
  margin-bottom: 10px;

}
.pagination>li>a, .pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #111214;
  text-decoration: none;
background-color: #f15a24;
    background-image: -webkit-linear-gradient(bottom, #c3292d, #f15a24);
    background-image: -moz-linear-gradient(bottom, #c3292d, #f15a24);
    background-image: -o-linear-gradient(bottom, #c3292d, #f15a24);
    background-image: -ms-linear-gradient(bottom, #c3292d, #f15a24);
    background-image: linear-gradient(to top, #c3292d, #f15a24);
  border: none;
  margin-right: 2px;
}
.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
  color: #ee5624;
background-color:transparent;
  border-color: #ddd;
    background-image: none;
}
.pagination>li:last-child>a, .pagination>li:last-child>span, 
.pagination>li:first-child>a, .pagination>li:first-child>span {
  border-radius: 0px;

}
.offer-img {
    padding-left: 0px;
    height: auto;
}
.offer-img img {
    width:100%;
}
.offer-holder {
    background-color: #000;
    margin-bottom: 30px;

   
}
.offer-title {
    float: left;
    font-size: 18px;
    font-weight: 600;
    color:#fff;
    padding: 15px;
    padding-left: 0px;
}
.offer-price {
    float: right;
    font-size: 20px;
    color:#aca595;
    font-style: italic;
    padding: 13px;
    padding-right: 20px;
}
.cost {
    color:#ef5724;
    font-weight: 700;
    font-style: normal;
}
.now {
    color:#fff;
}
.offer-text p {
    clear: both;
}
.badge-small {
    width: 87px;
    height: 99px;
    background-image: url(../images/badge-small.png);
    background-repeat: no-repeat;
    background-position: left center;
    display: block;
    position: absolute;
    bottom:10px;
    color:#fff;
    text-align: left;
}
.percent {
    padding-top: 25px;
    padding-left: 15px;
    padding-bottom: 0px;
    font-size: 25px;
    display: block;
    line-height: 25px;


}
.off {
    margin-top: -5px;
    font-size: 25px;
    font-weight: bold;
        padding-left: 15px;
            display: block;


}
.product-first-para {
    margin-top: 20px;
}
.contact-details {
    margin-top: -15px;
}
.contact-start {
    padding-top: 10px;
    font-weight: bold;
    font-style: 18px;
    color: #beb29a;
    display:block;
}
.mapWrap {
    width:100%;
 
}
.frm-contact {
    padding-top: 15px;
}
.btn-primary {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background-color: #03afbe;
    background-image: -webkit-linear-gradient(bottom, #c3292d, #f15a24);
    background-image: -moz-linear-gradient(bottom, #c3292d, #f15a24);
    background-image: -o-linear-gradient(bottom, #c3292d, #f15a24);
    background-image: -ms-linear-gradient(bottom, #c3292d, #f15a24);
    background-image: linear-gradient(to top, #c3292d, #f15a24);
    border-color: #c52c2d;
    font-weight: bold;
    margin-bottom: 30px;
}
.btn-primary:hover {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background-color: #c52c2d;
    background-image: none;

    border-color: #c52c2d;
}
iframe {
    height:234px;
}
@media (max-width: 767px) {
    .no-pad-mob {
        padding: 0px;
    }
    img.logo-large {
        width: 80%;
        height: auto;
    }
    .badge-pos {
        position: absolute;
        right: 0px;
        top: 125px;
        z-index: 11113;
        display: none;
        color:
    }
    .tel {
        padding-top: 35px;
        font-size: 15px;
        line-height: 18px;
    }
    .tel strong {
        font-size: 15px;
    }
    .slogan {
        font-size: 11px;
        letter-spacing: -0.1px;
    }
    .navbar-toggle .icon-bar {
        display: block;
        width: 26px;
        height: 3px;
        border-radius: 1px;
    }
    .navbar-toggle {
        position: relative;
        float: right;
        padding: 9px 10px;
        margin-top: 30px;
        margin-right: 15px;
        margin-bottom: 8px;
        background-color: transparent;
        background-image: none;
        border: none;
        border-radius: 4px;
    }
    .navbar-inverse .navbar-toggle .icon-bar {
        background-color: #ee5624;
    }
    .navbar-inverse .navbar-toggle:focus,
    .navbar-inverse .navbar-toggle:hover {
        background-color: transparent;
    }
    .navbar-inverse .navbar-nav>li>a.active,
    .navbar-inverse .navbar-nav>li>a:hover {
        color: #fff;
        border-bottom: solid 2px #ee5624;
    }
    .navbar-inverse .navbar-nav>li>a {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 2px;
        padding-right: 2px;
        font-weight: 700;
        color: #b3ac9c;
        border-bottom: solid 2px transparent;
    }
    h1 {
        font-size: 28px;
        line-height: 36px;
    }
    .thumbnail .caption {
        height: auto;
        padding-bottom: 10px;
    }
    .social {
        display: block;
        text-align: center;
    }
    .social img {
        margin-top: 20px;
    }
    .social p {
        margin-top: 0px;
        padding-top: 0px;
        padding-bottom: 10px;
        display: block;
        text-align: center;
        font-size: 11px;
        float: none;
    }
    .breadcrumbs-holder {
        height: auto;
    }
    .inner-title {
        float: none;
        display: block;
        text-align: center;
        padding: 0px;
    }
    .breadcrumb {
        float: none;
        display: block;
        text-align: center;
        clear: both;
        padding-top: 0px;
        padding-bottom: 20px;
        padding-right: 0px;
        padding-left: 0px;
    }
    p.category-intro {
        padding-left: 0px;
        padding-right: 0px;
    }
    .right-gutter {
        height: auto;
          min-height: 300px;
    }
    .offer-img {
        padding-right: 0px;
   
    }
    .offer-margin {
        padding-right: 0px;
        padding-left: 0px;
    }
    .offer-text {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
    }
    .offer-price {
        float: left;
        padding-left: 0px;
        font-size: 16px;
        padding-top: 0px;
        clear: left;
        
    }
    .offer-title {
        padding-bottom: 5px;
        font-size: 20px;
    }
    .blog-left {
        margin-left: 0px;
        margin-right: 0px;
    }
    .news-item, .product-item {
        padding-left: 0px;
        padding-right: 0px;
    }
    iframe {
    height:134px;
    }
    .under-nav-top-margin {
  margin-top: 20px;
}
    
}

@media (min-width: 768px) and (max-width: 992px) {
    .tel {
        padding-top: 37px;
        font-size: 14px;
        color: #d1c3a8;
        line-height: 15px;
    }
    .tel strong {
        font-size: 14px;
        color: #fff;
        font-weight: 700;
    }
    .slogan {
        float: left;
        display: block;
        clear: left;
        color: #fff;
        font-size: 10px;
        letter-spacing: 0.1px;
        font-weight: 400;
    }
    .navbar-inverse .navbar-nav>li {
        padding-left: 5px;
        padding-right: 5px;
    }
    .navbar-inverse .navbar-nav>li>a {
        font-size: 12px;
        padding-top: 15px;
        padding-bottom: 24px;
        padding-left: 2px;
        padding-right: 2px;
        font-weight: 700;
        color: #b3ac9c;
        border-bottom: solid 6px transparent;
    }
    .thumbnail h3 {
        font-size: 13px;
    }
    .home-item .caption p {
        font-size: 12px;
    }
    .recent-text a {

        font-size: 13px;
    }
    .recent-text p {
        font-size: 12px;
    
    }
    .offer-img {
        padding-right: 0px;
   
    }
    .offer-text {
        padding-left: 20px;
        padding-right: 20px;
    }
    .offer-price {
        float: left;
        padding-left: 0px;
        font-size: 16px;
        padding-top: 0px;
    }
    .offer-title {
        padding-bottom: 5px;
        font-size: 20px;
    }
    iframe {
    height:434px;
    }

    .social p {
        text-align: right;
    }

    .social img {
        margin-top: 15px;
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .recent-text a {

    font-size: 13px;
    }
    .recent-text p {
    font-size: 12px;

    }
    iframe {
    height:314px;
}

}

@media (min-width: 1201px) {}

.offer-gallery {
    margin-bottom: 15px;
}

.blog-left .product-item img {
    max-width: 100%;
    height: auto;
}